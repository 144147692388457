import { formatList } from '@watershed/intl/formatters';
import { Trans, useLingui } from '@lingui/react/macro';
import {
  Box,
  Card,
  Divider,
  Drawer,
  Fade,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  useState,
  useId,
  Fragment,
  useMemo,
  useTransition,
  useRef,
} from 'react';
import { search } from 'fast-fuzzy';

import ChevronsRightIcon from '@watershed/icons/components/ChevronsRight';
import SearchIcon from '@watershed/icons/components/Search';
import ColumnIcon from '@watershed/icons/components/Column';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import ListFilterIcon from '@watershed/icons/components/ListFilter';

import {
  GridValidRowModel,
  gridQuickFilterValuesSelector,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
  gridFilterModelSelector,
  GridFilterItem,
  useGridRootProps,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
} from './DataGrid';
import Button, { ButtonProps } from '../Button';
import { Pill } from '../Pill';
import { TextFieldNonFormik } from '../Form/TextField';
import {
  ColumnPreset,
  DataGridDrawerProps,
  DataGridDrawerSize,
} from './DataGridTypes';
import CollapsibleSection from '../CollapsibleSection';
import PopoverMenu from '../PopoverMenu';
import SegmentedControl, { SegmentedControlProps } from '../SegmentedControl';
import { getFilterOperatorDisplayString, FilterPanel } from './FilterPanel';
import { mixinSx } from '@watershed/style/styleUtils';
import ToggleChip from '../ToggleChip';
import UndoIcon from '@watershed/icons/components/Undo';
import useLocale from '@watershed/intl/frontend/useLocale';
import { t } from '@lingui/core/macro';
import CloseIcon from '@watershed/icons/components/Close';
import { DateTime } from 'luxon';

const DRAWER_SIZE_TO_WIDTH_PX: Record<DataGridDrawerSize, number> = {
  sm: 320,
  md: 384,
  lg: 512,
};
const PADDING_PX = 12;

// Corresponds to the width of the drawer when collapsed; this can be used to
// apply padding to the right side of the grid. Please update this if you change
// the drawer width. We may want to find some way to make the right-padding more
// automatic.
export const DRAWER_CONTAINER_WIDTH_PX = 61;

const COLLAPSED_WIDTH_PX = 36;
const COLLAPSE_ANIMATION_DURATION_MS = 250;
const FADE_ANIMATION_DURATION_MS = COLLAPSE_ANIMATION_DURATION_MS * 1.2;

export type DataGridDrawerSection<
  T extends DataGridDrawerSubsection =
    | DataGridDrawerSubsection
    | DataGridDrawerSubsectionColumns
    | DataGridDrawerSubsectionFilter
    | DataGridDrawerSubsectionSegmentedControl,
> = {
  title?: LocalizedString;
  subsections: Array<T>;
  hideWhenCollapsed?: boolean;
  // Specifies the ordering of the sections - by default, sections are ordered by the order they are defined below.
  sectionOrder?: number;
  // Specifies the view section position, which can be used to place the view section in a specific position.
  viewSectionPosition?: number;
};

export type DataGridDrawerSubsectionProps<T extends DataGridDrawerSubsection> =
  {
    subsection: T;
    setDrawerCollapsed: (drawerCollapsed: boolean) => void;
    showColumnSearch?: boolean;
  };

export interface DataGridDrawerSubsection {
  description?: LocalizedString;
  icon?: React.ReactNode;
  badgeCount?: number;
  Component: (props: DataGridDrawerSubsectionProps<any>) => JSX.Element;
}

interface DataGridDrawerSubsectionSegmentedControl
  extends DataGridDrawerSubsection {
  segmentedControlProps: SegmentedControlProps<any>;
}
function isSegmentedControlSubsection(
  subsection: DataGridDrawerSubsection
): subsection is DataGridDrawerSubsectionSegmentedControl {
  return 'segmentedControlProps' in subsection;
}

type ColumnDef = { headerName: LocalizedString | undefined; field: string };

interface DataGridDrawerSubsectionColumns extends DataGridDrawerSubsection {
  columnDefs: Array<ColumnDef>;
  columnPresets?: Array<ColumnPreset>;
}

interface DataGridDrawerSubsectionFilter extends DataGridDrawerSubsection {
  columnDefs: Array<ColumnDef>;
}

/**
 * DataGridDrawerContainer
 * The Drawer wrapper around the drawer contents. The drawer contents themselves
 * are overridable by passing in a custom component to the `drawer` slot.
 */
export function DataGridDrawerContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Drawer
      open
      anchor="right"
      variant="persistent"
      sx={{
        '& .MuiDrawer-paper': {
          position: 'absolute',
          zIndex: (theme) => theme.zIndex.drawer,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          p: `${PADDING_PX}px`,
        },
      }}
    >
      {children}
    </Drawer>
  );
}

/**
 * DataGridDrawer
 * This is the default Watershed DataGrid drawer component, which allows for
 * controlling filtering, column visibility, search, and more! This component
 * can be overridden via slots.drawer if necessary.
 */
export function DataGridDrawer<R extends GridValidRowModel>({
  columnPresets = [],
  columns = [],
  hideColumnSelector = false,
  hideFilters = false,
  hideSearch = false,
  segmentedControlProps,
  size = 'md',
  drawerOpenInitially = false,
  customSections,
  showColumnSearch = false,
  viewSectionPosition = 0,
}: DataGridDrawerProps<R>) {
  const [drawerCollapsed, setDrawerCollapsed] = useState(!drawerOpenInitially);
  const handleSubsectionIconButtonClick = () => {
    setDrawerCollapsed(false);
  };
  const columnDefs = useMemo(
    () =>
      columns
        .map(({ headerName, field }) => ({ headerName, field }))
        .filter(isNotNullish),
    [columns]
  );
  const apiRef = useGridApiContext();
  const columnVisibilities = useGridSelector(
    apiRef,
    gridColumnVisibilityModelSelector
  );
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const segmentedControlHasIcons = segmentedControlProps?.options.every(
    (o) => o.icon !== undefined
  );
  const columnCount = columnDefs.length;
  const hiddenColumnCount = Object.values(columnVisibilities).filter(
    (v) => v === false
  ).length;

  const viewSubsections = [
    hideFilters
      ? null
      : {
          description: 'Filter',
          icon: <ListFilterIcon size={16} />,
          Component: FilterSubsection,
          columnDefs,
          badgeCount:
            filterModel.items.length > 0 ? filterModel.items.length : undefined,
        },
    hideColumnSelector
      ? null
      : {
          description: 'Columns',
          icon: <ColumnIcon size={16} />,
          // If all columns are visible, no badge -- otherwise, the number of visible columns
          badgeCount:
            hiddenColumnCount === 0
              ? undefined
              : columnCount - hiddenColumnCount,
          Component: (props: DataGridDrawerSubsectionProps<any>) => (
            <ColumnsSubsection {...props} showColumnSearch={showColumnSearch} />
          ),
          columnDefs,
          columnPresets,
        },
  ].filter(isNotNullish);
  // New: Sorting sections based on the order prop
  const sortedSections = useMemo(() => {
    const allSections: Array<DataGridDrawerSection> = [
      ...(hideSearch
        ? []
        : [
            {
              subsections: [
                {
                  description: t({
                    message: 'Search',
                    context: 'Drawer section title: Search rows',
                  }),
                  icon: <SearchIcon size={16} />,
                  badgeCount: 0,
                  Component: SearchSubsection,
                },
              ],
            },
          ]),
      ...(segmentedControlProps
        ? [
            {
              subsections: [
                {
                  Component: SegmentedControlSubsection,
                  segmentedControlProps,
                },
              ],
              hideWhenCollapsed: !segmentedControlHasIcons,
            },
          ]
        : []),
      ...(viewSubsections.length > 0
        ? [
            {
              title: 'View',
              subsections: viewSubsections,
              sectionOrder: viewSectionPosition,
            },
          ]
        : []),
      ...(customSections ?? []),
    ];
    return allSections.sort(
      (a, b) => (a.sectionOrder ?? 0) - (b.sectionOrder ?? 0)
    );
  }, [
    hideSearch,
    segmentedControlProps,
    viewSubsections,
    customSections,
    segmentedControlHasIcons,
    viewSectionPosition,
  ]);

  return (
    <Box
      sx={{
        display: 'grid',
        width: drawerCollapsed
          ? COLLAPSED_WIDTH_PX
          : DRAWER_SIZE_TO_WIDTH_PX[size],
        transition: (theme) =>
          `all ${COLLAPSE_ANIMATION_DURATION_MS}ms ${theme.transitions.easing.easeInOut}`,
      }}
    >
      <Fade
        timeout={FADE_ANIMATION_DURATION_MS}
        in={drawerCollapsed}
        unmountOnExit
      >
        <Stack
          divider={<Divider sx={{ marginBlock: 1 }} />}
          gap={1}
          sx={{
            width: COLLAPSED_WIDTH_PX,
            // Grid makes the animatable elements stack on top of each other
            gridRowStart: 1,
            gridColumnStart: 1,
          }}
        >
          {sortedSections
            .filter((s) => !s.hideWhenCollapsed)
            // hide if there are no icons to show
            .filter((s) => s.subsections.some((ss) => ss.icon))
            .map((section, i) => (
              <Fragment key={section.title || i}>
                {section.subsections
                  // hide if it does not have an icon
                  .filter((subsection) => subsection.icon)
                  .map((subsection) => (
                    <SubsectionIconButton
                      key={subsection.description + '_subsectionkey'}
                      subsection={subsection}
                      onClick={handleSubsectionIconButtonClick}
                    />
                  ))}
              </Fragment>
            ))}
        </Stack>
      </Fade>
      <Fade
        timeout={FADE_ANIMATION_DURATION_MS}
        in={!drawerCollapsed}
        unmountOnExit
      >
        <Stack
          sx={{
            width: DRAWER_SIZE_TO_WIDTH_PX[size],
            // Grid makes the animatable elements stack on top of each other
            gridRowStart: 1,
            gridColumnStart: 1,
          }}
        >
          {sortedSections.map((section, i) => (
            <Fragment key={section.title || i}>
              <MaybeCollapsibleSection
                key={section.title}
                title={section.title}
              >
                <Stack sx={{ gap: 1.5, paddingBlockEnd: 2 }}>
                  {section.subsections.map((subsection) => (
                    <subsection.Component
                      key={subsection.description + '_subsectionkey'}
                      subsection={subsection}
                      setDrawerCollapsed={setDrawerCollapsed}
                    />
                  ))}
                </Stack>
              </MaybeCollapsibleSection>
              <Divider />
            </Fragment>
          ))}
        </Stack>
      </Fade>
    </Box>
  );
}

function MaybeCollapsibleSection({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: LocalizedString;
}) {
  if (title) {
    return (
      <CollapsibleSection
        titleVariant="h4"
        chevronOnly
        title={title}
        sx={{ paddingBlockStart: 1.5 }}
      >
        {children}
      </CollapsibleSection>
    );
  }
  return <>{children}</>;
}

function SubsectionIconButton({
  onClick,
  subsection,
}: {
  onClick: () => void;
  subsection: DataGridDrawerSubsection;
}) {
  if (isSegmentedControlSubsection(subsection)) {
    const { options } = subsection.segmentedControlProps;
    // For display in the collapsed sidebar, we only show the icons. If there
    // are no icons to show, let's just omit the segmented control from the
    // sidebar.
    const iconOnlyOptions = options
      .filter((o) => o.icon !== undefined)
      .map((o) => ({
        ...o,
        title: undefined,
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        tooltip: o.tooltip ?? String(o.title) ?? undefined,
      }));
    if (iconOnlyOptions.length === 0) {
      return null;
    }
    return (
      <SegmentedControl
        orientation="vertical"
        noPadding
        {...subsection.segmentedControlProps}
        // Re-format the options shown in the sidebar. If the option has an icon, just show the icon!
        options={iconOnlyOptions}
        buttonSx={{
          // Width is constrained in the collapsed sidebar; 7px makes the buttons align to the container
          p: '7px',
        }}
        sx={{ p: 0 }}
      />
    );
  }
  return (
    <Tooltip title={subsection.description ?? ''} placement="left">
      <Button
        onClick={onClick}
        sx={{
          p: 1,
          gap: 1,
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: 0,
          minHeight: '36px',
          height: 'fit-content',
        }}
      >
        {subsection.icon}
        {subsection.badgeCount ? (
          <Pill
            size="small"
            color="primary"
            label={subsection.badgeCount}
            sx={{
              width: '100%',
              minWidth: 3,
              '.MuiChip-label': { p: 0 },
            }}
          />
        ) : null}
      </Button>
    </Tooltip>
  );
}

function SearchSubsection(
  props: DataGridDrawerSubsectionProps<DataGridDrawerSubsection>
) {
  const apiRef = useGridApiContext();
  const { t } = useLingui();
  const quickFilterValues = useGridSelector(
    apiRef,
    gridQuickFilterValuesSelector
  );

  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    apiRef.current.setQuickFilterValues([e.currentTarget.value]);
  };
  return (
    <Stack direction="row" gap={1}>
      <TextFieldNonFormik
        id={useId()}
        placeholder={t({
          message: 'Search…',
          context: 'search text field placeholder',
        })}
        type="search"
        visuallyHideLabel
        sx={{ flexGrow: 1 }}
        onChange={onChangeSearchValue}
        value={quickFilterValues?.[0] || ''}
      />
      <Button
        onClick={() => props.setDrawerCollapsed(true)}
        startIcon={<ChevronsRightIcon size={16} />}
      />
    </Stack>
  );
}

function SegmentedControlSubsection(
  props: DataGridDrawerSubsectionProps<DataGridDrawerSubsectionSegmentedControl>
) {
  return <SegmentedControl {...props.subsection.segmentedControlProps} />;
}

function SmallPopoverButton(props: ButtonProps) {
  return (
    <Button
      variant="text"
      endIcon={<ChevronDownIcon color="text.secondary" size={12} />}
      size="small"
      {...props}
      sx={mixinSx({ color: 'text.secondary' }, props.sx)}
    />
  );
}

function FilterItem({
  item,
  columnDefs,
  onClick,
  onRemove,
}: {
  item: GridFilterItem;
  columnDefs: Array<ColumnDef>;
  onClick: () => void;
  onRemove: () => void;
}) {
  const { t, i18n } = useLingui();
  const locale = useLocale();

  const formatValue = (value: string | Date | Array<string>) => {
    // Handle the case where MUI's data format (yyyy-MM-dd) is expected by the backend, but
    // we want a localised date on the front-end.
    if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      const date = DateTime.fromFormat(value, 'yyyy-MM-dd').toJSDate();
      return i18n.date(date, {
        dateStyle: 'medium',
      });
    }

    if (value instanceof Date) {
      return i18n.date(value, {
        dateStyle: 'medium',
      });
    }
    if (Array.isArray(value)) {
      return formatList(value, { locale });
    }
    return value;
  };

  const colDef =
    item.field === GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD
      ? {
          headerName: t({
            context:
              'Name of table column shown when the filtering is done on a user selected table grouping',
            message: 'Group',
          }),
          field: item.field,
        }
      : columnDefs.find((d) => d.field === item.field);

  if (!colDef) {
    return null;
  }
  const renderHeaderName = (
    name: LocalizedString | string | null | undefined
  ): React.ReactNode =>
    name === '#' ? (
      <ToggleChip
        onClick={onClick}
        isSelected={false}
        label={
          <Typography variant="h4" color={(theme) => theme.palette.grey30}>
            <Trans context="Placeholder copy for when user hasn't specified a column on which to filter a data grid">
              Column
            </Trans>
          </Typography>
        }
      />
    ) : (
      name
    );

  const headerName =
    renderHeaderName(colDef.headerName) ?? renderHeaderName(item.field);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        height: '28px',
        paddingInline: 0.5,
        gap: 0.5,
        maxWidth: '100%',
        '&:hover .remove-button': {
          opacity: 1,
        },
      }}
    >
      <Typography variant="h5" noWrap>
        {headerName}
      </Typography>
      <Typography variant="body2" sx={{ flexShrink: 0 }}>
        {getFilterOperatorDisplayString(item.operator)}
      </Typography>
      {item.operator !== 'isEmpty' && item.operator !== 'isNotEmpty' && (
        <ToggleChip
          sx={{ maxWidth: '100%', overflow: 'hidden' }}
          onClick={onClick}
          isSelected={false}
          label={
            item.value === undefined || item.value.toString().length === 0 ? (
              <Typography variant="h4" color={(theme) => theme.palette.grey30}>
                <Trans context="Placeholder copy when user hasn't provided an value for the filter on a data grid">
                  Condition
                </Trans>
              </Typography>
            ) : Array.isArray(item.value) ? (
              formatList(item.value, { locale })
            ) : (
              formatValue(item.value)
            )
          }
        />
      )}
      <Button
        className="remove-button"
        isIcon
        onClick={onRemove}
        variant="text"
        size="small"
        startIcon={<CloseIcon size={14} />}
        sx={{
          minWidth: 'unset',
          p: 0.5,
          opacity: 0,
          transition: 'opacity 0.2s',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      />
    </Stack>
  );
}

function FilterSubsection(
  props: DataGridDrawerSubsectionProps<DataGridDrawerSubsectionFilter>
) {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const filterPanelAnchorEl = useRef(null);
  const { slotProps } = useGridRootProps();
  const filterPanelProps = slotProps?.filterPanel ?? {};
  const handleRemoveFilter = (index: number) => {
    const newItems = [...filterModel.items];
    newItems.splice(index, 1);
    apiRef.current.setFilterModel({
      ...filterModel,
      items: newItems,
    });
  };
  return (
    <Stack alignItems="flex-start" ref={filterPanelAnchorEl}>
      <Typography variant="body2">
        <Trans context="Header above data grid filters section">Filter</Trans>
      </Typography>
      <Stack gap={1} sx={{ pt: 1, width: '100%' }} alignItems="flex-start">
        {filterModel.items.map((item, index) => (
          <FilterItem
            key={index}
            item={item}
            columnDefs={props.subsection.columnDefs}
            onClick={() => setFilterPanelVisible(true)}
            onRemove={() => handleRemoveFilter(index)}
          />
        ))}
        <SmallPopoverButton onClick={() => setFilterPanelVisible(true)}>
          <Trans context="Button copy to add a record to a list of filters on a data grid">
            Add
          </Trans>
        </SmallPopoverButton>
      </Stack>
      <Popover
        open={filterPanelVisible}
        onClose={() => setFilterPanelVisible(false)}
        anchorEl={filterPanelAnchorEl.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <FilterPanel {...filterPanelProps} />
      </Popover>
    </Stack>
  );
}

function ColumnsSubsection(
  props: DataGridDrawerSubsectionProps<DataGridDrawerSubsectionColumns> & {
    showColumnSearch?: boolean;
  }
) {
  const { t } = useLingui();
  const { columnDefs } = props.subsection;
  const columnPresets = props.subsection.columnPresets ?? [];
  const [searchColumnsValue, setSearchColumnsValue] = useState('');
  const [_, searchTransition] = useTransition();
  const apiRef = useGridApiContext();
  const columnVisibilities = useGridSelector(
    apiRef,
    gridColumnVisibilityModelSelector
  );
  const visibleColumnDefs = useMemo(() => {
    if (!searchColumnsValue) {
      return columnDefs;
    }
    return search(searchColumnsValue, columnDefs, {
      keySelector: (colDef) => colDef.field + colDef.headerName,
    });
  }, [columnDefs, searchColumnsValue]);

  const allColumnsVisible = Object.values(columnVisibilities).every(
    (v) => v !== false
  );

  const handleResetAll = () => {
    apiRef.current.setColumnVisibilityModel({});
  };

  const searchId = useId();

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">
          <Trans context="Header above a UI affordance to select the columns to display in a grid">
            Columns
          </Trans>
        </Typography>
        {columnPresets.length > 0 && (
          <PopoverMenu
            trigger={
              <SmallPopoverButton>
                <Trans context="Popover menu trigger for a list of saved view presets">
                  Presets
                </Trans>
              </SmallPopoverButton>
            }
            options={columnPresets.map((p) => ({
              title: p.presetName,
              onClick: () => {
                apiRef.current.setColumnVisibilityModel(
                  columnDefs.reduce(
                    (acc, { field }) => ({
                      ...acc,
                      [field]: p.presetColumns.includes(field),
                    }),
                    {}
                  )
                );
              },
            }))}
          />
        )}
      </Stack>
      <Stack gap={1}>
        <Card
          sx={
            props.showColumnSearch
              ? {
                  p: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  borderRadius: 1,
                }
              : { border: 0, p: 0 }
          }
        >
          {props.showColumnSearch && (
            <Stack gap={1} pt={0.25}>
              <TextFieldNonFormik
                id={searchId}
                placeholder={t({
                  message: 'Search columns…',
                  context:
                    'text field placeholder for a search field on multiple columns of a data grid',
                })}
                type="search"
                visuallyHideLabel
                label={
                  <Trans context="text field label for a search field on multiple columns of a data grid">
                    Search columns
                  </Trans>
                }
                sx={{ flexGrow: 1, paddingBlockEnd: 0.25, paddingInline: 0.25 }}
                onChange={(e) => {
                  searchTransition(() => {
                    setSearchColumnsValue(e.currentTarget.value);
                  });
                }}
                value={searchColumnsValue}
              />
              <Divider />
            </Stack>
          )}
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
            paddingBlock={0.5}
            paddingInline={0.25}
          >
            {visibleColumnDefs.map(({ headerName, field }) => (
              <ToggleChip
                key={field}
                isSelected={columnVisibilities[field] !== false}
                onClick={() => {
                  apiRef.current.setColumnVisibility(
                    field,
                    columnVisibilities[field] === false
                  );
                }}
                sx={{ flexShrink: 0 }}
                label={headerName ?? field}
              />
            ))}
          </Stack>
        </Card>
        {!allColumnsVisible && (
          <Button
            variant={props.showColumnSearch ? 'contained' : 'text'}
            onClick={handleResetAll}
            sx={{ width: 'fit-content', color: 'text.secondary' }}
            startIcon={<UndoIcon size={16} />}
          >
            <Trans context="Button to reset column selection for a data grid">
              Reset
            </Trans>
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export function DataGridDrawerSubsectionWrapper({
  children,
  ...props
}: React.PropsWithChildren<
  DataGridDrawerSubsectionProps<DataGridDrawerSubsection>
>) {
  return (
    <Stack gap={1}>
      <Typography variant="body2">{props.subsection.description}</Typography>
      {children}
    </Stack>
  );
}
